@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200&display=swap');


body {
   /*font-family: 'Lato', sans-serif;*/
   font-family: 'Oswald', sans-serif;
  background-color: black;
}

/*.footer {
  background: url(./images/footer.svg) top center no-repeat;
  background-size: cover;
  background-color: white;
}*/

.footer {
  background: linear-gradient(rgba(2,2,2,.7),rgba(0,0,0,.7)),url(./images/school_1_com.jpg) fixed center center no-repeat;
  background-color: black;
}

a {
  color: black;
}

a:hover {
  color: rgb(224, 179, 30);
}

/** {
  border: 1px solid red;
}*/

.service-image { 
  width: 100%; 
  height: auto; 
}


